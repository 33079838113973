import { makeAutoObservable, runInAction } from "mobx";
import { apiService } from "../ApiService";
import { AnalyticsDailyResultDTO, AnalyticsSyncJob, KlaviyoDailyResult, KlaviyoSyncJob, TypeformDailyResult, TypeformSyncJob } from "../backend-types";
import { API_CONFIG } from "../config/api.config";
import { RootStore } from "./RootStore";

export class AnalyticsStore {
  results: AnalyticsDailyResultDTO[] = [];
  klaviyoResults: KlaviyoDailyResult[] = [];
  typeformResults: TypeformDailyResult[] = [];
  currentSyncJob: AnalyticsSyncJob | null = null;
  currentKlaviyoSyncJob: KlaviyoSyncJob | null = null;
  currentTypeformSyncJob: TypeformSyncJob | null = null;
  isLoading: boolean = false;
  isLoadingKlaviyo: boolean = false;
  isLoadingTypeform: boolean = false;
  error: string | null = null;

  private flowNameMap: Record<string, string> = {
    "66ba53906855f58fee497c75": "Kundenrückgewinnungsflow", 
    "6686e2b7a066aff314f84ac1": "Probetrainingsflow",
    "671b7ff2efd4b62a844f4185": "Kundenrückgewinnungsflow Austria",
    "67160da40d32338b6cb9375c": "MWM Flow"
  };

  private campaignNameMap: Record<string, string> = {
    "probetraining": "Probetraining Conversions",
    "winback": "Rückgewinnungs-Kampagne",
  };

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  async fetchAnalytics(fromDate: Date, toDate: Date) {
    this.isLoading = true;
    this.error = null;
    
    try {
      const formattedFromDate = fromDate.toISOString().split('T')[0];
      const formattedToDate = toDate.toISOString().split('T')[0];
      
      const results = await apiService.get<AnalyticsDailyResultDTO[]>(
        `${API_CONFIG.ENDPOINTS.ANALYTICS_RESULTS}?fromDate=${formattedFromDate}&toDate=${formattedToDate}`
      );
      
      runInAction(() => {
        this.results = results;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
        this.isLoading = false;
      });
      throw error;
    }
  }

  async fetchKlaviyoAnalytics(fromDate: Date, toDate: Date) {
    this.isLoadingKlaviyo = true;
    this.error = null;
    
    try {
      const formattedFromDate = fromDate.toISOString().split('T')[0];
      const formattedToDate = toDate.toISOString().split('T')[0];
      
      const results = await apiService.get<KlaviyoDailyResult[]>(
        `${API_CONFIG.ENDPOINTS.KLAVIYO_RESULTS_DATE_RANGE}?fromDate=${formattedFromDate}&toDate=${formattedToDate}`
      );
      
      runInAction(() => {
        this.klaviyoResults = results;
        this.isLoadingKlaviyo = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
        this.isLoadingKlaviyo = false;
      });
      throw error;
    }
  }

  async fetchMonitoredCampaignsResults() {
    this.isLoadingKlaviyo = true;
    this.error = null;
    
    try {
      const results = await apiService.get<KlaviyoDailyResult[]>(
        `${API_CONFIG.ENDPOINTS.KLAVIYO_MONITORED_CAMPAIGNS_RESULTS}`
      );
      
      runInAction(() => {
        this.klaviyoResults = results;
        this.isLoadingKlaviyo = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
        this.isLoadingKlaviyo = false;
      });
      throw error;
    }
  }

  async getKlaviyoSyncStatus(date: Date) {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      const status = await apiService.get<KlaviyoSyncJob>(
        `${API_CONFIG.ENDPOINTS.KLAVIYO_SYNC_STATUS}/${formattedDate}`
      );
      
      runInAction(() => {
        this.currentKlaviyoSyncJob = status;
      });
      
      return status;
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
      });
      throw error;
    }
  }

  async getSyncStatus(date: Date) {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      const status = await apiService.get<AnalyticsSyncJob>(
        `${API_CONFIG.ENDPOINTS.ANALYTICS_SYNC_STATUS}/${formattedDate}`
      );
      
      runInAction(() => {
        this.currentSyncJob = status;
      });
      
      return status;
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
      });
      throw error;
    }
  }

  async triggerSync(date: Date) {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      await apiService.post(
        `${API_CONFIG.ENDPOINTS.ANALYTICS_TRIGGER_SYNC}/${formattedDate}`,
        null
      );
      
      // Fetch the new sync status after triggering
      await this.getSyncStatus(date);
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
      });
      throw error;
    }
  }

  async fetchTypeformAnalytics(fromDate: Date, toDate: Date) {
    this.isLoadingTypeform = true;
    this.error = null;
    
    try {
      const formattedFromDate = fromDate.toISOString().split('T')[0];
      const formattedToDate = toDate.toISOString().split('T')[0];
      
      const results = await apiService.get<TypeformDailyResult[]>(
        `${API_CONFIG.ENDPOINTS.TYPEFORM_RESULTS_DATE_RANGE}?fromDate=${formattedFromDate}&toDate=${formattedToDate}`
      );
      
      runInAction(() => {
        this.typeformResults = results;
        this.isLoadingTypeform = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
        this.isLoadingTypeform = false;
      });
      throw error;
    }
  }

  async fetchMonitoredFormsResults() {
    this.isLoadingTypeform = true;
    this.error = null;
    
    try {
      const results = await apiService.get<TypeformDailyResult[]>(
        `${API_CONFIG.ENDPOINTS.TYPEFORM_MONITORED_FORMS_RESULTS}`
      );
      
      runInAction(() => {
        this.typeformResults = results;
        this.isLoadingTypeform = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
        this.isLoadingTypeform = false;
      });
      throw error;
    }
  }

  async getTypeformSyncStatus(date: Date) {
    try {
      const formattedDate = date.toISOString().split('T')[0];
      const status = await apiService.get<TypeformSyncJob>(
        `${API_CONFIG.ENDPOINTS.TYPEFORM_SYNC_STATUS}/${formattedDate}`
      );
      
      runInAction(() => {
        this.currentTypeformSyncJob = status;
      });
      
      return status;
    } catch (error) {
      runInAction(() => {
        this.error = error instanceof Error ? error.message : 'An error occurred';
      });
      throw error;
    }
  }

  clear() {
    runInAction(() => {
      this.results = [];
      this.klaviyoResults = [];
      this.typeformResults = [];
      this.currentSyncJob = null;
      this.currentKlaviyoSyncJob = null;
      this.currentTypeformSyncJob = null;
      this.isLoading = false;
      this.isLoadingKlaviyo = false;
      this.isLoadingTypeform = false;
      this.error = null;
    });
  }

  getFlowName(flowId: string): string {
    return this.flowNameMap[flowId] || flowId;  // Fallback to ID if name not found
  }

  getCampaignName(campaign: string): string {
    return this.campaignNameMap[campaign] || campaign;  // Fallback to campaign if name not found
  }

  // Computed values for analytics
  get totalMessagesSent() {
    return this.results.reduce((sum, result) => sum + result.totalMessagesSent, 0);
  }

  get totalCosts() {
    return this.results.reduce((sum, result) => sum + result.totalCosts, 0);
  }

  get totalEnrollments() {
    return this.results.reduce((sum, result) => sum + result.totalEnrollments, 0);
  }

  get messagesByFlow() {
    return this.results.reduce((acc, result) => {
      const flowName = this.getFlowName(result.flowId);
      const key = `${result.studioName} - ${flowName}`;
      
      if (!acc[key]) {
        acc[key] = {
          messages: 0,
          costs: 0,
          studioName: result.studioName,
          flowId: result.flowId,
          flowName: flowName
        };
      }
      acc[key].messages += result.totalMessagesSent;
      acc[key].costs += result.totalCosts;
      return acc;
    }, {} as Record<string, { 
      messages: number; 
      costs: number; 
      studioName: string; 
      flowId: string;
      flowName: string;
    }>);
  }

  // Klaviyo Analytics
  get totalKlaviyoEvents() {
    return this.klaviyoResults.reduce((sum, result) => sum + result.eventCount, 0);
  }

  get totalReferrals() {
    return this.klaviyoResults.reduce((sum, result) => sum + result.referralCount, 0);
  }

  get eventsByCampaign() {
    return this.klaviyoResults.reduce((acc, result) => {
      const campaign = this.getCampaignName(result.utmCampaign || 'unknown');
      
      if (!acc[campaign]) {
        acc[campaign] = {
          events: 0,
          referrals: 0
        };
      }
      acc[campaign].events += result.eventCount;
      acc[campaign].referrals += result.referralCount;
      return acc;
    }, {} as Record<string, { 
      events: number;
      referrals: number;
    }>);
  }

  get eventsByStudioAndCampaign() {
    return this.klaviyoResults.reduce((acc, result) => {
      const studioName = result.studioName || result.studioId || 'Unknown Studio';
      
      if (!acc[studioName]) {
        acc[studioName] = {
          totalEvents: 0,
          totalReferrals: 0,
          campaigns: {}
        };
      }
      
      acc[studioName].totalEvents += result.eventCount;
      acc[studioName].totalReferrals += result.referralCount;
      
      const campaignName = this.getCampaignName(result.utmCampaign || 'unknown');
      const medium = result.utmMedium || 'unknown';
      const campaignKey = `${campaignName} (${medium})`;
      
      if (!acc[studioName].campaigns[campaignKey]) {
        acc[studioName].campaigns[campaignKey] = {
          events: 0,
          referrals: 0,
          utmCampaign: result.utmCampaign,
          utmMedium: result.utmMedium,
          campaignName: campaignName
        };
      }
      
      acc[studioName].campaigns[campaignKey].events += result.eventCount;
      acc[studioName].campaigns[campaignKey].referrals += result.referralCount;
      
      return acc;
    }, {} as Record<string, { 
      totalEvents: number;
      totalReferrals: number;
      campaigns: Record<string, {
        events: number;
        referrals: number;
        utmCampaign: string;
        utmMedium: string;
        campaignName: string;
      }>;
    }>);
  }

  get dailyData() {
    const dataMap = new Map<string, {
      date: string;
      messageCount: number;
      costs: number;
    }>();

    this.results.forEach(result => {
      const dateStr = result.date.toString().split('T')[0];
      const existing = dataMap.get(dateStr) || { date: dateStr, messageCount: 0, costs: 0 };
      
      dataMap.set(dateStr, {
        date: dateStr,
        messageCount: existing.messageCount + result.totalMessagesSent,
        costs: existing.costs + result.totalCosts
      });
    });

    return Array.from(dataMap.values())
      .sort((a, b) => a.date.localeCompare(b.date));
  }

  get klaviyoDailyData() {
    const dataMap = new Map<string, {
      date: string;
      eventCount: number;
      referralCount: number;
    }>();

    this.klaviyoResults.forEach(result => {
      const dateStr = result.date.toString().split('T')[0];
      const existing = dataMap.get(dateStr) || { date: dateStr, eventCount: 0, referralCount: 0 };
      
      dataMap.set(dateStr, {
        date: dateStr,
        eventCount: existing.eventCount + result.eventCount,
        referralCount: existing.referralCount + result.referralCount
      });
    });

    return Array.from(dataMap.values())
      .sort((a, b) => a.date.localeCompare(b.date));
  }

  // Typeform Analytics
  get totalTypeformSubmissions() {
    return this.typeformResults.reduce((sum, result) => sum + result.submissionCount, 0);
  }

  get typeformResultsByCampaign() {
    return this.typeformResults.reduce((acc, result) => {
      const campaign = this.getCampaignName(result.utmCampaign || 'unknown');
      
      if (!acc[campaign]) {
        acc[campaign] = {
          submissionCount: 0
        };
      }
      acc[campaign].submissionCount += result.submissionCount;
      return acc;
    }, {} as Record<string, { 
      submissionCount: number;
    }>);
  }

  get typeformResultsByStudio() {
    return this.typeformResults.reduce((acc, result) => {
      const studioName = result.studioName || result.studioId || 'Unknown Studio';
      
      if (!acc[studioName]) {
        acc[studioName] = [];
      }
      
      acc[studioName].push(result);
      return acc;
    }, {} as Record<string, TypeformDailyResult[]>);
  }
}