export const API_CONFIG = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8888",
  ENDPOINTS: {
    // AUTH
    LOGIN: "/auth/login",
    ROLES_ALL: "/auth/get-all-roles",
    // USERS
    USERS_CURRENT: "/auth/get-current-user",
    USERS_ALL: "/users/customer",
    USERS_GET: "/users/get",
    USERS_CREATE: "/users/create",
    USERS_UPDATE: "/users/update",
    USERS_DELETE: "/users/delete",
    USERS_CHANGE_PASSWORD: "/users/change-password",
    // CUSTOMERS
    CUSTOMERS_GET: "/customers/get",
    // GROUPS
    GROUPS_ALL: "/groups/all",
    GROUPS_GET: "/groups/get",
    GROUPS_CREATE: "/groups/create",
    GROUPS_UPDATE: "/groups/update",
    GROUPS_DELETE: "/groups/delete",
    // ANALYTICS
    ANALYTICS_RESULTS: "/api/analytics/results",
    ANALYTICS_SYNC_STATUS: "/api/analytics/sync/status",
    ANALYTICS_TRIGGER_SYNC: "/api/analytics/sync",
    // KLAVIYO
    KLAVIYO_RESULTS: "/api/klaviyo/results",
    KLAVIYO_RESULTS_DATE_RANGE: "/api/klaviyo/results/date-range",
    KLAVIYO_SYNC_STATUS: "/api/klaviyo/sync/status",
    KLAVIYO_MONITORED_CAMPAIGNS: "/api/klaviyo/monitored-campaigns",
    KLAVIYO_MONITORED_CAMPAIGNS_RESULTS: "/api/klaviyo/monitored-campaigns/results",
    // TYPEFORM
    TYPEFORM_RESULTS: "/api/typeform/results",
    TYPEFORM_RESULTS_DATE_RANGE: "/api/typeform/results/date-range",
    TYPEFORM_SYNC_STATUS: "/api/typeform/sync/status",
    TYPEFORM_MONITORED_FORMS: "/api/typeform/monitored-forms",
    TYPEFORM_MONITORED_FORMS_RESULTS: "/api/typeform/monitored-forms/results",
  },
};